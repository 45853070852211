/*!
 * Javascript Mediachimie
 */
var mediachimie;
mediachimie = (function($j) {
	'use strict';

	var editoSlider, mobileMenu, quickAccess, showMediachimie, metiersMenu, espaceJeunesSlider, accordion, printResume, iframeColorBox, faqAccordion, tooltipMenu, linkTargetBlank, linkPartenaires, enseignantsMenu, mediathequeMenu, espacejeunesRecherche;

	/**
	 * Edito
	 */
	editoSlider = function(slick_duration) {
		var $slider = $j('.home-edito__slider .slider'),
			$pause = $j('.slider-pause'),
			play = true;

		if (typeof(slick_duration) == 'undefined')
			var slick_duration = 2;

		$slider.slick({
			arrows: false,
			// fade: true,
			dots: true,
			autoplay: true,
			autoplaySpeed: (slick_duration*1000),
			pauseOnHover: true,
			slidesToShow: 1,
			adaptiveHeight: true,
			responsive: [
			    {
			      breakpoint: 320,
			      settings: {
					 autoplay: false
			      }
			    }
			  ]
		});

		$pause.on('click', function() {
			if (play) {
				$slider.slick('slickPause');
			}
			else {
				$slider.slick('slickPlay');
			}
			$pause.find('i').toggleClass('icon-play', play);

			play =! play;
		});

		var view_all_html = '<div class="home-edito__view-all">'+$j('.home-edito__view-all').html()+'</div>';
		$j('.home-edito__view-all').remove();

		$slider.find('.slick-dots').before(view_all_html);
		$slider.find('.slick-dots').appendTo($j('.slider-dots-custom'));

	};

	/**
	 * Espace jeunes
	 */
	espaceJeunesSlider = function(slick_duration) {
		var $slider = $j('.slider');

		if (typeof(slick_duration) == 'undefined')
			var slick_duration = 2;

		$slider.slick({
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: (slick_duration*1000),
			pauseOnHover: false,
			adaptiveHeight: true
		});

		$slider.find('.slick-dots').appendTo($j('.slider-dots-custom'));

		var $slider = $j('.slider_multiple');

		$slider.slick({
			arrows: true,
			dots: false,
			pauseOnHover: false,
			adaptiveHeight: true,
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
			    {
			      breakpoint: 700,
			      settings: {
			      	slidesToScroll: 1,
					slidesToShow: 1
			      }
			    }
			  ]
		});

		var nua = navigator.userAgent;
		var is_android = ((nua.indexOf('Mozilla/5.0') > -1 && nua.indexOf('Android ') > -1 &&     nua.indexOf('AppleWebKit') > -1) && !(nua.indexOf('Chrome') > -1));

		if (is_android) {
			var width = jQuery('.container').width();
			jQuery('.jeunes-edito__content, .jeunes-home__block').css('max-width', width);
		}

	};

	/**
	 * Menu burger sur mobile
	 */
	mobileMenu = function() {
		$j('.site-nav__burger').click(function() {
			$j(this).toggleClass('site-nav__burger--open');
			$j('.site-nav__list').slideToggle();
		});
	};

	/**
	 * Afficher ressources mediachimie (sans pagination)
	 */
	showMediachimie = function() {
		$j('.show_mediachimie').click(function(e) {
			e.preventDefault();
			$j('.show_mediachimie').removeClass('mediatheque-list__showall');
			$j(this).addClass('mediatheque-list__showall');

			if ($j(this).attr('data-show') != 'all') {
				$j('.mediatheque-list__article').hide();
				$j('.mediatheque-list__article.'+$j(this).attr('data-show')).show();
			}
			else {
				$j('.mediatheque-list__article').show();
			}
		});
	};

	enseignantsMenu = function() {
		if ($j('.enseignants--menu__icon').length > -1 ) {
			var path = window.location.pathname.substring(1);
				$j('.enseignants_menu').hide();

			$j('.enseignants--menu__icon').click(function() {
				if ($j(this).hasClass('enseignants--menu__icon--open')) {
					$j(this).removeClass('enseignants--menu__icon--open');
					$j('.enseignants_menu').slideUp();
				}
				else {
					$j(this).addClass('enseignants--menu__icon--open');
					$j('.enseignants_menu').slideDown();
				}
			});
		}
	}

	metiersMenu = function() {
		if ($j('.metiers--menu__icon').length > -1 ) {
			var path = window.location.pathname.substring(1);
			if (path != 'espace-metiers') {
				$j('.metiers_menu').hide();
			}



			$j('.metiers--menu__icon').click(function() {
				if ($j(this).hasClass('metiers--menu__icon--open')) {
					$j(this).removeClass('metiers--menu__icon--open');
					$j('.metiers_menu').slideUp();
				}
				else {
					$j(this).addClass('metiers--menu__icon--open');
					$j('.metiers_menu').slideDown();
				}
			});
		}
	}

	mediathequeMenu = function() {
		if ($j('.mediatheque--menu__icon').length > -1 ) {
			var path = window.location.pathname.substring(1);
			$j('.mediatheque_menu').hide();

			$j('.mediatheque--menu__icon').click(function() {
				if ($j(this).hasClass('mediatheque--menu__icon--open')) {
					$j(this).removeClass('mediatheque--menu__icon--open');
					$j('.mediatheque_menu').slideUp();
				}
				else {
					$j(this).addClass('mediatheque--menu__icon--open');
					$j('.mediatheque_menu').slideDown();
				}
			});
		}
	}

	/**
	 * Quick access footer
	 */
	quickAccess = function() {
		$j('.quick-access__opener').on('click', function(event) {
			event.preventDefault();
			$j(this).toggleClass('quick-access__opener--open').siblings('.quick-access__content').slideToggle();
		});
	};

	accordion = function() {
		$j( ".accordion h2 a:not(.link)" ).click(function(e) {
			e.preventDefault();
			var $section = $j(this).closest('section');
			var id = $section.attr('id');
			$section.find('div').slideToggle();

			$j( ".accordion section").each(function() {
				if ($j(this).attr('id') != id) {
					$j(this).find('div').slideUp();
				}
			});

			$j('.accordion .lvl3__block').slideUp();
		});

		$j('.accordion .lvl3__title').click(function(e) {
			e.preventDefault();
			var ul = $j(this).next('.lvl3__block');
			var id = $j(this).attr('data-id');
			ul.slideToggle();

			$j('.accordion .lvl3__block').each(function() {
				if ($j(this).attr('id') != id) {
					$j(this).slideUp();
				}
			});
		})
	}

	tooltipMenu = function() {
        $j('.tooltip').tooltipster();
	}

	/**
	* Afficher ou masquer une portion de texte
	*/
	printResume = function ()
	{
		// Masquer le texte
		$j('.togglable').hide();

		// Afficher ou masquer au clic sur le bouton
		$j('.btn-togglable').click(function(event) {
			event.preventDefault();

			var visible = $j(this).parent().find('.togglable:visible').length;
			$j(this).toggleClass('btn-toggled');

			if( !visible) {
				$j(this).text('Masquer le résumé');
				$j(this).parent().find('.togglable').css('display', 'inline');
				$j(this).parent().find('.togglable-hellip').css('display', 'none');
				$j(this).parent().find('.toggable-default').css('display', 'none');
			}
			else {
				$j(this).parent().find('.togglable').css('display', 'none');
				$j(this).parent().find('.togglable-hellip').css('display', 'inline');
				$j(this).parent().find('.toggable-default').css('display', 'inline');

				$j(this).text('Afficher tout le résumé');
			}

		});

	}

	iframeColorBox = function() {
		$j('.iframe').colorbox({
			iframe:true,
			width:"710",
			height:"420",
			opacity:0.8,
			close: '<a href="javascript:function nohref(){}" name="Fermer" alt="Fermer" title="Fermer" >Fermer</a>'
		});
	}

	faqAccordion = function() {

		$j('#faq-accordeon .toggle').on( "click", function(e) {
			e.preventDefault();

			var $this = $j(this);
			var index = $this.closest('li').index();


			// créer un hash a chaque question ouverte
			var hashParam = $this.children('h1').attr("id");
			if (hashParam === undefined) {
				// TODO: supprimer le hash
			} else {
				window.location.hash = hashParam;
			}


			if ($this.next().hasClass('show')) {
				$this.next().slideUp('fast', function() {
					$this.next().removeClass('show');
				});
				$this.removeClass('open-title');
			} else {
				$this.parent().parent().find('li .inner').removeClass('show');
				$this.parent().parent().find('li .inner').hide();
				$this.next().toggleClass('show');
				$this.next().slideToggle('fast', function(){
					var offset = $this.offset().top;
//					console.log(offset);
					$j('body').animate({scrollTop:offset});
				});
				$this.addClass('open-title');
			}

			if (!$this.hasClass('item-question')) {
				$j('#faq-accordeon .toggle').each(function() {
					if ($j(this).closest('li').index() != index) {
						$j(this).removeClass('open-title');
					}
				});
			}
		});


		// ouvrir la question si un hash existe
		var pathname = window.location.pathname;
		if (pathname == '/liste-faq') {
			// on est sur la bonne page, y a t il un hash ?
			if(window.location.hash) {
				var questionId = window.location.hash;
				// ouvrir le titre de la rubrique
				$j(questionId).closest('.title').children("a").trigger( "click" );
				// ouvrir la question
				$j(questionId).parent().trigger( "click" );
				// scroller jusqu'a la response
//				$j('body').delay(500).animate({scrollTop:$j(questionId).offset().top});
			}
		}


	}

	linkTargetBlank = function() {
		$j('a[target="_blank"').each(function(e) {
			if ($j(this).find('img').length > 0) {
				$j(this).addClass('noicon');
			}
		});
	}

	linkPartenaires = function() {
		$j('.partenaires a').each(function(e) {
			// console.log('partenaires'+$j(this).find('img').length);
			if ($j(this).find('img').length > 0) {
				$j(this).addClass('nolist');
			}
		});
	}

	espacejeunesRecherche = function() {
		$j('.form_search_jeunes').submit(function(e) {
			e.preventDefault();
			document.location.href = $j(this).attr('action')+$j('input[name="jeunes_search"]').val();
		});
	}

	return {
		editoSlider: editoSlider,
		mobileMenu: mobileMenu,
		quickAccess: quickAccess,
		showMediachimie: showMediachimie,
		enseignantsMenu: enseignantsMenu,
		mediathequeMenu: mediathequeMenu,
		metiersMenu: metiersMenu,
//		metiersMenu: metiersMenu,
		espaceJeunesSlider: espaceJeunesSlider,
		accordion: accordion,
		printResume: printResume,
		iframeColorBox: iframeColorBox,
		faqAccordion: faqAccordion,
		tooltipMenu: tooltipMenu,
		linkTargetBlank: linkTargetBlank,
		linkPartenaires: linkPartenaires,
		espacejeunesRecherche: espacejeunesRecherche
	};

})(jQuery);

/**
 * Appel aux fonctions quand jQuery est prêt
 */
var $j = jQuery.noConflict();
var slick_duration;
$j(function() {
	mediachimie.tooltipMenu();
	mediachimie.editoSlider(slick_duration);
	mediachimie.mobileMenu();
	mediachimie.quickAccess();
	mediachimie.showMediachimie();
	mediachimie.metiersMenu();
	mediachimie.enseignantsMenu();
	mediachimie.mediathequeMenu();
	mediachimie.espaceJeunesSlider(slick_duration);
	mediachimie.accordion();
	mediachimie.printResume();
	mediachimie.iframeColorBox();
	mediachimie.faqAccordion();
	mediachimie.linkTargetBlank();
	mediachimie.linkPartenaires();
	mediachimie.espacejeunesRecherche();
});
